<template>
  <div :class="mainClass" v-if="ready">
    <!-- Categoría -->
    <template v-if="$route.path.startsWith('/categoria')">
      <VueSlickCarousel
        v-if="blogPosts.length > 0"
        v-bind="settingsCarouselPosts"
        dotsClass="slick-dots"
      >
        <BlogPostThumb
          class="thumb-post--related"
          v-for="(post, index) in blogPosts"
          :key="index"
          :post="post"
        />
      </VueSlickCarousel>
    </template>

    <!-- Producte -->
    <template v-else>
      <VueSlickCarousel
        v-if="blogPosts.length > 0"
        v-bind="settingsCarouselPostsProduct"
        dotsClass="slick-dots"
      >
        <BlogPostThumb
          class="thumb-post--related"
          v-for="(post, index) in blogPosts"
          :key="index"
          :post="post"
        />
      </VueSlickCarousel>
    </template>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import BlogPostThumb from "../../blog/components/BlogPostThumb";

export default {
  name: "RelatedPosts",
  components: {
    VueSlickCarousel,
    BlogPostThumb,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
    mainClass: {
      type: String,
      default: "g-post__items g-post__items--related",
    },
  },
  data() {
    return {
      ready: false,
      currentIndex: 0,
      settingsCarouselPosts: {
        slidesToShow: 3,
        centerMode: true,
        infinite: true,
        dots: false,
        arrows: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              arrows: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              arrows: false,
              draggable: true,
            },
          },
        ],
      },
      settingsCarouselPostsProduct: {
        slidesToShow: 4,
        centerMode: true,
        infinite: true,
        dots: false,
        arrows: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
              arrows: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              arrows: false,
              draggable: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    async getPost(postId) {
      try {
        return await this.ghostApi.posts.read({
          id: postId,
          include: "tags",
        });
      } catch (error) {
        console.log(error);
      }
      return {};
    },
  },
  async mounted() {
    let module;
    try {
      module = await import("@tryghost/content-api");
    } catch (error) {
      console.log(error);
    }
    if (module) {
      this.ghostApi = new module.default({
        url: process.env.BLOG_URL,
        key: process.env.BLOG_API_KEY,
        version: process.env.BLOG_API_VERSION,
      });
      this.blogPosts = [];
      if (this.posts && this.posts.length > 0) {
        for (let i = 0; i < this.posts.length; i++) {
          const blogPost = await this.getPost(this.posts[i]);
          this.blogPosts.push(blogPost);
        }
        this.ready = true;
      }
    }
  },
};
</script>
